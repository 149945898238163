import { useParams } from 'react-router-dom';
import {
  CustomFormProps,
  DialogBody,
  LOCALE_DE,
  PropsWithServices,
  RenderSequentialUserTasks,
  formatDateString,
} from '../../../infrastructure';
import { Identity } from '@atlas-engine/atlas_engine_client';
import {
  ArbeitspapiereDatenLadenResult,
  ArbeitspapiereDatenLadenResultFromJSON,
  MaschinenlaufplanDaten,
  Rueckmeldung1Daten,
} from 'wacoplast_wws__api';
import {
  Button,
  Dialog,
  HTMLTable,
  Spinner,
} from '@blueprintjs/core';
import styles from './Rueckmeldung1Print.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { formatNumber } from '../../../infrastructure/formatNumber';
import { AufdruckArtKeyMapping } from '../../../stammdaten';

export function Rueckmeldung1Print(props: PropsWithServices<{ data?: { rueckmeldung1: Rueckmeldung1Daten, maschinenlaufplan: MaschinenlaufplanDaten }, getIdentity: () => Identity }>): JSX.Element {
  const { correlation_id } = useParams();

  if (props.data) {
    return <Rueckmeldung1 {...props.data} hidePrintButton />;
  }

  return (
    <RenderSequentialUserTasks
      correlationId={correlation_id ?? ''}
      getIdentity={props.getIdentity}
      atlasEngineClient={props.atlasEngineClient}
      getComponent={(userTask) => {
        if (userTask.userTaskConfig.customForm === 'wacoplast-wws.ArbeitspapiereAnzeigen') {
          return Rueckmeldung1CustomForm;
        }
        return null;
      }}
    >
      <Dialog
        isOpen={true}
        canOutsideClickClose={false}
        isCloseButtonShown={false}
        canEscapeKeyClose={false}
        title='Einen Moment bitte'
      >
        <DialogBody>
          <Spinner />
        </DialogBody>
      </Dialog>
    </RenderSequentialUserTasks>
  );
}

function Rueckmeldung1CustomForm(props: CustomFormProps<ArbeitspapiereDatenLadenResult>): JSX.Element {
  const payload = ArbeitspapiereDatenLadenResultFromJSON(props.tokenPayload);

  return <Rueckmeldung1 rueckmeldung1={payload.rueckmeldung1} maschinenlaufplan={payload.maschinenlaufplan} />;
}

function Rueckmeldung1(props: { rueckmeldung1: Rueckmeldung1Daten, maschinenlaufplan: MaschinenlaufplanDaten, hidePrintButton?: boolean }): JSX.Element {
  const { rueckmeldung1, maschinenlaufplan } = props;

  return (
    <div className={styles.rueckmeldung1_page_container}>
      {!props.hidePrintButton &&
        <Button className={styles.print_button} onClick={() => window.print()}><FontAwesomeIcon icon={faPrint} /> Drucken</Button>
      }
      <HTMLTable className={styles.rueckmeldung1_table}>
        <thead>
          <tr>
            <th className={styles.kuau_header}>KUAU: {rueckmeldung1.kundenauftrag_nummer}</th>
            <td className={styles.kuau_pau_spacer} />
            <th className={styles.pau_header}>PAU: {rueckmeldung1.produktionsauftrag_nummer}</th>
            <td colSpan={3} />
            <th className={styles.right_normal}>MLP</th>
            <th className={styles.center_normal}>Rückmeldung 1</th>
            <th colSpan={2} className={styles.center_normal}>{new Date().toLocaleDateString(LOCALE_DE, { day: '2-digit', month: '2-digit', year: 'numeric' })} {new Date().toLocaleTimeString(LOCALE_DE, { timeStyle: 'short' })}</th>
          </tr>
          <tr>
            <th className={styles.kunde_header}>Kunde:</th>
            <td className={styles.kunde_header}>{rueckmeldung1.kunde_name}</td>
            <td>{rueckmeldung1.empfaenger_name ?? rueckmeldung1.kunde_name}</td>
            <th>Bestell-Nr.:</th>
            <td colSpan={2}>{rueckmeldung1.kunde_bestell_nummer}</td>
            <th colSpan={2} className={styles.rueckmeldung_header}>Rückmeldung</th>
            <th className={styles.blm_nr_header}>BLM-Nr.:</th>
            <td className={styles.blm_nr}>{rueckmeldung1.blasmaschine_nummer}</td>
          </tr>
          <tr>
            <th>Bestelldatum:</th>
            <td colSpan={2}>{formatDateString(rueckmeldung1.bestelldatum)}</td>
            <th>Liefertermin:</th>
            <td colSpan={2}>{rueckmeldung1.liefertermin ? formatDateString(rueckmeldung1.liefertermin) : ''}</td>
            <th colSpan={2}>Stretcher-Nr:</th>
            <td colSpan={2} className={styles.left}>{rueckmeldung1.stretcher && <>{maschinenlaufplan.kantenschutz ? '1, ' : ''}2, 3</>}</td>
          </tr>
          <tr>
            <th>FL-Typ:</th>
            <td colSpan={2}>{rueckmeldung1.flaschentyp_bezeichnung}</td>
            <th>Farbe:</th>
            <td colSpan={2}>{rueckmeldung1.farbe_bezeichnung}</td>
            <th colSpan={2}>Stückzahl:</th>
            <td colSpan={2}>{formatNumber(rueckmeldung1.menge_produktionsauftrag)}</td>
          </tr>
          <tr>
            <th>FL-Typ-Spez.:</th>
            <td colSpan={2}>{rueckmeldung1.flaschentyp_spezifikation ? 'mit BLZ' : 'ohne BLZ'}</td>
            <th>Platz- / Farb- /<br /> Batch-Nr:</th>
            <td colSpan={2}>/ {rueckmeldung1.farbe_nummer} / {rueckmeldung1.farbe_batch_nummer}</td>
            <th colSpan={2}>VP - Art:</th>
            <td colSpan={2}>{rueckmeldung1.verpackungs_einheit_bezeichnung}</td>
          </tr>
          <tr>
            <th className={styles.small}>Vol.[ml] /<br /> Halsteil:</th>
            <td>{formatNumber(rueckmeldung1.volumen)}</td>
            <td className={styles.right}>{rueckmeldung1.halsteil_bezeichnung}</td>
            <th>Einfärbung [%]:</th>
            <td colSpan={2}>{formatNumber(rueckmeldung1.einfaerbungsgrad, 1)}</td>
            <th colSpan={2}>Stückzahl pro {maschinenlaufplan.karton_geschlossen !== undefined ? 'Karton' : 'Tray'}:</th>
            <td colSpan={2}>{formatNumber(rueckmeldung1.flaschen_pro_vpe)}</td>
          </tr>
          <tr>
            <th>Gewicht [g]:</th>
            <td>{formatNumber(rueckmeldung1.artikel_gewicht, 1)}</td>
            <td>&#177; {formatNumber(rueckmeldung1.gewichtstoleranz)}</td>
            <th>Material:</th>
            <td colSpan={2}>{maschinenlaufplan.werkstoff_bezeichnung}; {maschinenlaufplan.werkstoff_typ_1_bezeichnung} {maschinenlaufplan.werkstoff_typ_2_bezeichnung && <>/</>} {maschinenlaufplan.werkstoff_typ_2_bezeichnung}</td>
            <th colSpan={2}>{maschinenlaufplan.karton_geschlossen !== undefined ? 'Kartons' : 'Trays'} pro Palette:</th>
            <td colSpan={2}>{formatNumber(rueckmeldung1.vpes_pro_palette)}{maschinenlaufplan.vpes_pro_stapel_2 ? <> / {maschinenlaufplan.vpes_pro_stapel_2}</> : <></>}</td>
          </tr>
          <tr>
            <th>FL-Typ-Nr.:</th>
            <td colSpan={2}>{rueckmeldung1.flaschentyp_nummer}</td>
            <th />
            <td colSpan={2}>{ }</td>
            <th colSpan={2} className={styles.small} />
            <td />
            <td />
          </tr>
          <tr className={styles.thin_boi}>
            <td />
            <td colSpan={2}>{rueckmeldung1.vpe_is_karton && <>Karton mit/ohne Druck</>}</td>
            <th colSpan={2} className={styles.yellow_cell}>{rueckmeldung1.vpe_is_karton ? 'Karton' : 'Tray'} neu/alt</th>
            <td colSpan={2}>{rueckmeldung1.vpe_is_karton && <>Karton offen/geschlossen</>}</td>
            <td>{rueckmeldung1.vpe_is_karton && <>Polybeutel</>}</td>
            <th className={styles.yellow_cell}>Kantenschutz</th>
            <td />
          </tr>
          <tr className={styles.thin_boi}>
            <td />
            <td colSpan={2}>{maschinenlaufplan.karton_aufdruck_art && AufdruckArtKeyMapping[maschinenlaufplan.karton_aufdruck_art]}</td>
            <td colSpan={2}>{rueckmeldung1.verpackungs_einheit_zustand}</td>
            <td colSpan={2}>{rueckmeldung1.vpe_is_karton && (maschinenlaufplan.karton_geschlossen ? 'geschlossen' : 'offen')}</td>
            <td>{rueckmeldung1.vpe_is_karton && (maschinenlaufplan.karton_polybeutel ? 'ja' : 'nein')}</td>
            <td>{rueckmeldung1.kantenschutz ? 'ja' : 'nein'}</td>
            <td />
          </tr>
          <tr className={styles.table_data_header}>
            <th className={styles.schicht_header}>Schicht</th>
            <th className={styles.zaehler_header}>Zähler (total)<br />Start / Prod. - Ende</th>
            <th className={styles.produktion_header}>Produktion</th>
            <th className={styles.prod_gesamt_header}>Prod. gesamt</th>
            <th className={styles.leck_header}>Leck</th>
            <th className={styles.innen_1_header}>Innen 1</th>
            <th className={styles.innen_2_header}>Innen 2</th>
            <th className={styles.innen_3_header}>Innen 3</th>
            <th className={styles.datum_header}>Datum</th>
            <th className={styles.unterschrift_header}>Unterschrift</th>
          </tr>
          <tr className={styles.table_data_header}>
            <th className={styles.left}>Zähler ⇒<br />Ende PAUvor</th>
            <th />
            <td colSpan={8} />
          </tr>
        </thead>
        <tbody>
          <Rueckmeldung1TableRows rows={6} />
        </tbody>
      </HTMLTable>
    </div>
  );
}

function Rueckmeldung1TableRows({ rows }: { rows: number }): JSX.Element {
  return (
    <>
      {Array.from({ length: rows }).map((_, i) => (
        <>
          <tr>
            <th>Früh</th>
            <td />
            <td />
            <td />
            <td />
            <td />
            <td />
            <td />
            <td />
            <td />
          </tr>
          <tr>
            <th>Mittag</th>
            <td />
            <td />
            <td />
            <td />
            <td />
            <td />
            <td />
            <td />
            <td />
          </tr>
          <tr>
            <th>Nacht</th>
            <td />
            <td />
            <td />
            <td />
            <td />
            <td />
            <td />
            <td />
            <td />
          </tr>
        </>
      ))}
    </>
  );
}
