import { Identity } from '@atlas-engine/atlas_engine_client';
import {
  CustomFormProps,
  DialogBody,
  PropsWithServices,
  RenderSequentialUserTasks,
  withServices,
} from '../../../infrastructure';
import { MaschinenlaufplanPrint } from './MLPPrint';
import { Dialog, Spinner } from '@blueprintjs/core';
import { useParams } from 'react-router-dom';
import { ArbeitspapiereDatenLadenResult, ArbeitspapiereDatenLadenResultFromJSON } from 'wacoplast_wws__api';
import * as html2pdf from 'html2pdf.js';
import { PDFDocument, PDFPage } from 'pdf-lib';

import './AllArbeitspapiere.module.scss';
import { StellblattPrint } from './StellblattPrint';
import {
  AbmusterungPrint,
  BegleitdokumentPrint,
  EtikettKartonPrint,
  EtikettTrayPrint,
  GewichtskontrollePrint,
  PruefprotokollPrint,
  QHinweisePrint,
  Rueckmeldung1Print,
  Rueckmeldung2Print,
  VPUnterschriftenPrint,
} from '.';
import { useEffect, useRef, useState } from 'react';

const PAGES_PER_DOCUMENT = 5;
const PORTRAIT_PAGE_HEIGHT = 1122;
const LANDSCAPE_PAGE_HEIGHT = 793;

export function AllArbeitspapierePrint(props: PropsWithServices<{ getIdentity: () => Identity }>): JSX.Element {
  const { correlation_id } = useParams();

  return (
    <RenderSequentialUserTasks
      correlationId={correlation_id ?? ''}
      getIdentity={props.getIdentity}
      atlasEngineClient={props.atlasEngineClient}
      getComponent={(userTask) => {
        if (userTask.userTaskConfig.customForm === 'wacoplast-wws.ArbeitspapiereAnzeigen') {
          return withServices(AllArbeitspapiere);
        }
        return null;
      }}
    >
      <Dialog
        isOpen={true}
        canOutsideClickClose={false}
        isCloseButtonShown={false}
        canEscapeKeyClose={false}
        title='Einen Moment bitte'
      >
        <DialogBody>
          <Spinner />
        </DialogBody>
      </Dialog>
    </RenderSequentialUserTasks>
  );
}

function AllArbeitspapiere(props: PropsWithServices<CustomFormProps<ArbeitspapiereDatenLadenResult>>): JSX.Element {
  const portraitPrintContainer = useRef<HTMLDivElement>(null);
  const landscapePrintContainer = useRef<HTMLDivElement>(null);
  const [percentage, setPercentage] = useState<number>(0);
  const payload = ArbeitspapiereDatenLadenResultFromJSON(props.tokenPayload);

  const generatePdf = async (): Promise<void> => {
    await new Promise(res => setTimeout(res, 100));
    const portraitHeight = portraitPrintContainer.current?.clientHeight ?? 0;
    const landscapeHeight = landscapePrintContainer.current?.clientHeight ?? 0;
    const pdfPromises = createPdfs(portraitHeight, landscapeHeight);
    const result = await mergePdfs(pdfPromises.vertical, pdfPromises.horizontal, props.config.restServiceUrl, setPercentage, payload);
    const url = base64PDFToBlobUrl(result);
    window.opener.postMessage({ type: 'pdfAsBlob', data: url }, window);
    window.close();
  };

  useEffect(() => generatePdf() as any, []);

  return (
    <>
      <Dialog
        isOpen={true}
        canOutsideClickClose={false}
        isCloseButtonShown={false}
        canEscapeKeyClose={false}
        title={`Einen Moment bitte. Arbeitspapiere werden erzeugt. (${percentage.toFixed(2)}%)`}
      >
        <DialogBody>
          <Spinner />
        </DialogBody>
      </Dialog>
      <div id='portraitPrint' ref={portraitPrintContainer}>
        <MaschinenlaufplanPrint {...props} data={payload.maschinenlaufplan} />
        <div className='pagebreak'>
          <StellblattPrint {...props} data={{ maschinenlaufplan: payload.maschinenlaufplan, stellblatt: payload.stellblatt }} />
        </div>
        <div className='pagebreak'>
          <PruefprotokollPrint {...props} data={{ maschinenlaufplan: payload.maschinenlaufplan, pruefprotokoll: payload.pruefprotokoll }} />
        </div>
        <div className='pagebreak'>
          <GewichtskontrollePrint {...props} data={{ maschinenlaufplan: payload.maschinenlaufplan, gewichtskontrolle: payload.gewichtstoleranz }} />
        </div>
        <div className='pagebreak'>
          <Rueckmeldung2Print {...props} data={{ maschinenlaufplan: payload.maschinenlaufplan, rueckmeldung2: payload.rueckmeldung2 }} />
        </div>
        {props.tokenPayload.begleitdokument &&
        <div className='pagebreak'>
          <BegleitdokumentPrint {...props} data={payload.begleitdokument} />
        </div>
        }
        {props.tokenPayload.etikett_karton &&
          <div className='pagebreak'>
            <EtikettKartonPrint {...props} data={payload.etikett_karton} />
          </div>
        }
        {props.tokenPayload.abmusterung &&
          <div className='pagebreak'>
            <AbmusterungPrint {...props} data={payload.abmusterung} />
          </div>
        }
        <div className='pagebreak'>
          <VPUnterschriftenPrint {...props} data={{ maschinenlaufplan: payload.maschinenlaufplan, vpUnterschriften: payload.vp_unterschriften }} />
        </div>
      </div>
      <div id='landscapePrint' ref={landscapePrintContainer}>
        <div className='pagebreak'>
          <Rueckmeldung1Print {...props} data={{ maschinenlaufplan: payload.maschinenlaufplan, rueckmeldung1: payload.rueckmeldung1 }} />
        </div>
        {props.tokenPayload.etikett_tray &&
          <div className='pagebreak'>
            <EtikettTrayPrint {...props} data={{ etikettTray: payload.etikett_tray!, maschinenlaufplan: payload.maschinenlaufplan }} />
          </div>
        }
        {payload.qhinweise.hinweise.length > 0 &&
        <div className='pagebreak'>
          <QHinweisePrint {...props} data={payload.qhinweise} />
        </div>
        }
      </div>
      {props.tokenPayload.packordnung &&
        <div className='pagebreak'>
          Packordnung
        </div>
      }
    </>
  );
}

function base64PDFToBlobUrl(base64: string): string {
  const binStr = atob(base64);
  const len = binStr.length;
  const arr = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    arr[i] = binStr.charCodeAt(i);
  }
  const blob = new Blob([arr], { type: 'application/pdf' });
  const url = URL.createObjectURL(blob);
  return url;
}

function createPdfs(portraitHeight: number, landscapeHeight: number): { vertical: Array<Promise<ArrayBuffer>>, horizontal: Array<Promise<ArrayBuffer>>} {
  const verticalPdfPromises = [];
  const horizontalPdfPromises = [];
  for (let i = 0; i < Math.ceil(portraitHeight / ((PORTRAIT_PAGE_HEIGHT + 1) * PAGES_PER_DOCUMENT)); i++) {
    verticalPdfPromises.push(html2pdf()
      .set({
        margin: 0,
        pagebreak: {
          mode: 'avoid-all' },
        image: { type: 'jpeg', quality: 1 },
        html2canvas: {
          height: Math.min(PORTRAIT_PAGE_HEIGHT * PAGES_PER_DOCUMENT, PORTRAIT_PAGE_HEIGHT * Math.ceil((portraitHeight - i * (PORTRAIT_PAGE_HEIGHT + 1) * PAGES_PER_DOCUMENT) / (PORTRAIT_PAGE_HEIGHT + 1))),
          y: i * PORTRAIT_PAGE_HEIGHT * PAGES_PER_DOCUMENT,
          scale: 2,
          letterRendering: true,
          ppi: 300 },
      })
      .from(document.getElementById('portraitPrint'))
      .outputPdf('arraybuffer'));
  }

  for (let i = 0; i < Math.ceil(landscapeHeight / ((LANDSCAPE_PAGE_HEIGHT + 1) * PAGES_PER_DOCUMENT)); i++) {
    horizontalPdfPromises.push(html2pdf()
      .set({
        margin: 0,
        pagebreak: {
          mode: 'avoid-all' },
        jsPDF: {
          format: 'a4', orientation: 'landscape' },
        image: { type: 'jpeg', quality: 1 },
        html2canvas: {
          height: Math.min(LANDSCAPE_PAGE_HEIGHT * PAGES_PER_DOCUMENT, LANDSCAPE_PAGE_HEIGHT * Math.ceil((landscapeHeight - i * (LANDSCAPE_PAGE_HEIGHT + 1) * PAGES_PER_DOCUMENT) / (LANDSCAPE_PAGE_HEIGHT + 1))),
          y: i * LANDSCAPE_PAGE_HEIGHT * PAGES_PER_DOCUMENT,
          scale: 2,
          letterRendering: true,
          ppi: 300 },
      })
      .from(document.getElementById('landscapePrint'))
      .outputPdf('arraybuffer'));
  }

  return { vertical: verticalPdfPromises, horizontal: horizontalPdfPromises };
}

async function mergePdfs(verticalDocuments: Array<Promise<ArrayBuffer>>, horizontalDocuments: Array<Promise<ArrayBuffer>>, restServiceUrl: string, pdfDoneCallback: (percentage: number) => void, payload: ArbeitspapiereDatenLadenResult): Promise<string> {
  const totalPdfCount = verticalDocuments.length + horizontalDocuments.length;
  let pdfsDone = 0;

  const mergedPdf = await PDFDocument.create();
  const verticalPages: Array<PDFPage> = [];
  const horizontalPages: Array<PDFPage> = [];
  const packordnungPages: Array<PDFPage> = [];
  const pages: Array<PDFPage> = [];

  await Promise.all(verticalDocuments.map(async pdf => {
    const partialPdf = await PDFDocument.load(await pdf);
    const copiedPages = await mergedPdf.copyPages(partialPdf, partialPdf.getPageIndices());
    verticalPages.push(...copiedPages);
    pdfsDone++;
    pdfDoneCallback(pdfsDone / totalPdfCount * 100);
  }));

  await Promise.all(horizontalDocuments.map(async pdf => {
    const partialPdf = await PDFDocument.load(await pdf);
    const copiedPages = await mergedPdf.copyPages(partialPdf, partialPdf.getPageIndices());
    horizontalPages.push(...copiedPages);
    pdfsDone++;
    pdfDoneCallback(pdfsDone / totalPdfCount * 100);
  }));

  if (payload.packordnung) {
    try {
      const packordnungData = await (await fetch(`${restServiceUrl}/file_storage/${payload.packordnung}`)).arrayBuffer();
      const packordnungPdf = await PDFDocument.load(packordnungData);
      const copiedPages = await mergedPdf.copyPages(packordnungPdf, packordnungPdf.getPageIndices());
      packordnungPages.push(...copiedPages);
    } catch {
      console.error('Could not parse packordnung. It is probably not a pdf.');
    }
  }

  const etikettKartonIndex = payload.begleitdokument ? 6 : 5;
  const abmusterungIndex = payload.etikett_karton ? etikettKartonIndex + 1 : etikettKartonIndex;
  const vpUnterschriftenStartIndex = payload.abmusterung ? abmusterungIndex + 1 : abmusterungIndex;
  const qhinweisStartIndex = payload.etikett_tray ? 2 : 1;

  // MLP
  pages.push(verticalPages[0]);
  // Stellblatt
  pages.push(verticalPages[1]);
  // QHinweise
  pages.push(...horizontalPages.slice(qhinweisStartIndex));
  // Pruefprotokoll
  pages.push(verticalPages[2]);
  // Packordnung
  pages.push(...packordnungPages);
  // Gewichtskontrolle
  pages.push(verticalPages[3]);
  //Begleitdokument
  if (payload.begleitdokument) {
    pages.push(verticalPages[5]);
  }
  // VP-Unterschriften
  pages.push(...verticalPages.slice(vpUnterschriftenStartIndex));
  // Rueckmeldung1
  pages.push(horizontalPages[0]);
  // Rueckmeldung2
  pages.push(verticalPages[4]);
  // Etikett
  if (payload.etikett_karton) {
    pages.push(verticalPages[etikettKartonIndex]);
  } else if (payload.etikett_tray) {
    pages.push(horizontalPages[1]);
  }
  // Abmusterung
  if (payload.abmusterung) {
    pages.push(verticalPages[abmusterungIndex]);
  }

  pages.forEach(page => mergedPdf.addPage(page));

  const mergedPdfFile = await mergedPdf.saveAsBase64();
  return mergedPdfFile;
}
